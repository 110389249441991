var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('add-edit-restricted-area'),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10"},[_c('div',{},[_c('title-plus',{attrs:{"title":_vm.$t('components.restrictedAreaManagement.headline'),"hidePlus":_vm.$acl.canNotCreate('Restricted Areas')},on:{"plus":_vm.add}})],1)]),_c('div',{staticClass:"flex flex-col mt-4 mb-10 space-x-0 space-y-4 md:space-x-4 md:space-y-0 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t(
              'components.restrictedAreaManagement.summary.totalRestrictedArea'
            ),"value":_vm.indexMetaData.summary.total,"variant":"gray"}})],1)]),(_vm.$acl.canNotView('Restricted Areas'))?_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")]):_c('FSTable',{attrs:{"fst-id":"RestrictedAreaIndex","headers":_vm.getTableHeaders,"endpoint":_vm.indexDataEndpoint,"isMultipleViewEnabled":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); },"view":function (v) { return (_vm.layoutView = v); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var data = ref.data;
return [(_vm.layoutView === 'list')?[_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRestrictedArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewServiceArea',
                      params: {
                        id: item.geofence.id ? item.geofence.id : '',
                      },
                    }}},[_vm._v(" "+_vm._s(item.geofence.name || '--')+" ")])],1),_c('FSTableRowItem',{attrs:{"text":item.address ? item.address : '--'}}),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_active ? 'green' : 'gray',"text":item.is_active ? 'Active' : 'Inactive',"profile":"fleet"}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"restrictedArea","data":item},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRestrictedArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('FSTableRowItem',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewServiceArea',
                      params: {
                        id: item.geofence.id ? item.geofence.id : '',
                      },
                    }}},[_vm._v(" "+_vm._s(item.geofence.name || '--')+" ")])],1),_c('FSTableRowItem',[_c('x-status',{attrs:{"variant":item.is_active ? 'green' : 'gray',"text":item.is_active ? 'Active' : 'Inactive',"profile":"fleet"}})],1)],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.parkingAreaManagement.table.columns.address' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.address || '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.parkingAreaManagement.table.columns.actions' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + itemIndex),attrs:{"type":"restrictedArea","data":item},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)])])])]):_vm._e()]:_vm._e()]})]:_vm._e(),(_vm.layoutView === 'grid')?_vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableGridItem',[_c('div',{staticClass:"bg-gray-50 rounded-lg flex justify-between items-center p-1"},[_c('div',[_c('router-link',{staticClass:"font-bold text-blue-600 capitalize",attrs:{"to":{
                      name: 'ViewRestrictedArea',
                      params: { id: item.id },
                    }}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('div',{staticClass:"flex items-center"},[_c('oto-edit-icon',{on:{"click":function($event){return _vm.edit(item)}}}),_c('MoreActionsDropdown',{key:("more-actions-" + _vm.index),attrs:{"type":"restrictedArea","data":item},on:{"exportKML":function($event){return _vm.handleExportKML(item)},"change":function($event){return _vm.handleAction('change', itemIndex, $event)},"delete":function($event){return _vm.handleAction('delete', itemIndex, $event)}}})],1)]),_c('div',{staticClass:"h-52"},[_c('MapView',{attrs:{"data":item}})],1)])],1)}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }